import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REFRESH_AUTH = "refreshAuth";
export const ENGINEER_LOGIN = "engineerLogin";
export const ENGINEER_LOGOUT = "engineerLogout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const commitErrors = (response) => {
  let requestErrors = [];
  let data = response.data;
  if (data && data.message) {
    requestErrors.push(data.message);
  } else if (response && response.status === 422) {
    if (data.errors) {
      for (let error in data.errors) {
        if (data.errors[error]) {
          for (let i = data.errors[error].length - 1; i >= 0; i--) {
            requestErrors.push(data.errors[error][i]);
          }
        }
      }
    } else {
      for (let error in data) {
        if (data[error]) {
          for (let i = data[error].length - 1; i >= 0; i--) {
            requestErrors.push(data[error][i]);
          }
        }
      }
    }
  } else if (response && response.status === 207) {
    requestErrors.push(data.error);
  } else if (response && response.status === 401) {
    requestErrors.push(data.error);
  } else if (response && response.status === 500) {
    requestErrors.push(data.message);
  } else if (response && response.status === 400) {
    requestErrors.push(data.message);
  } else if (response && response.status === 404) {
    requestErrors.push("Sorry ! The page you requested was not found.");
  } else if (response && response.status === 405) {
    requestErrors.push(data.message);
  } else {
    requestErrors.push(response.toString());
  }
  return requestErrors;
};

const actions = {
  [LOGIN](context, credentials) {
    context.commit(PURGE_AUTH);
    return new Promise((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          /*localStorage.clear();
          sessionStorage.clear();*/
          context.commit(SET_AUTH, data.data);
          resolve(data.data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [LOGOUT](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("auth/logout").then(() => {
        context.commit(PURGE_AUTH);
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("auth/verify").catch(() => {
        context.commit(PURGE_AUTH);
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [REFRESH_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("auth/refresh-token")
        .then(({ data }) => {
          context.commit(SET_AUTH, data.data);
        })
        .catch(() => {
          context.commit(PURGE_AUTH);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [ENGINEER_LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(PURGE_AUTH);
      ApiService.post("v1/engineer/auth/login", credentials)
        .then(({ data }) => {
          /*localStorage.clear();
          sessionStorage.clear();*/
          context.commit(SET_AUTH, data.data);
          resolve(data.data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [ENGINEER_LOGOUT](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("v1/engineer/auth/logout").then(() => {
        context.commit(PURGE_AUTH);
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
    state.errors = {};
    JwtService.saveToken(data.auth, data.user, data.menu, data.permissions);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
